import { mpsConfig } from './configurePersistable';
import { consoleDebug, isObject } from './utils';
var isSerializableProperty = function (obj) {
    var keys = ['key', 'serialize', 'deserialize'];
    if (!isObject(obj)) {
        consoleDebug(!!mpsConfig.debugMode, 'passed value is not an object', { obj: obj });
        return false;
    }
    return keys.every(function (key) {
        if (obj.hasOwnProperty(key) && typeof key !== 'undefined') {
            return true;
        }
        consoleDebug(!!mpsConfig.debugMode, "".concat(String(key), " not found in SerializableProperty"), { key: key, obj: obj });
        return false;
    });
};
export var makeSerializableProperties = function (properties) {
    return properties.reduce(function (acc, curr) {
        if (typeof curr === 'string') {
            acc.push({
                key: curr,
                serialize: function (value) { return value; },
                deserialize: function (value) { return value; },
            });
            return acc;
        }
        if (isSerializableProperty(curr)) {
            acc.push(curr);
            return acc;
        }
        return acc;
    }, []);
};
